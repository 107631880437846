import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { first, switchMap } from 'rxjs/operators';
import { CrossborderAuditApiService, CrossborderIbAuditApiService } from '../../../generated/apps-api';
import { cbAuditData } from '../../shared/components/results/shared-results.utils';
import { SetAuditCorrelationId } from '../../shared/state/shared.action';
import { SharedStateModel } from '../../shared/state/shared.model';
import { SaveCBAudit } from './cross-border-audit.action';
import { CrossBorderAuditStateModel } from './cross-border-audit.model';
import { CrossBorderSelectors } from './cross-border.selectors';

@State<CrossBorderAuditStateModel>({
  name: 'crossBorderAudit',
  defaults: {}
})
@Injectable()
export class CrossBorderAuditState {
  constructor(
    private store: Store,
    private crossborderAuditApiService: CrossborderAuditApiService,
    private crossborderIbAuditApiService: CrossborderIbAuditApiService
  ) {}

  @Action(SaveCBAudit)
  public saveCBAudit(ctx: StateContext<SharedStateModel>, { payload }: SaveCBAudit) {
    const data = cbAuditData(this.store);
    // In case of Financial Instruments we'll use the date from Financial Services
    const createdAt = payload?.generatedOn ? payload.generatedOn : data.generatedOn;
    const service$ = this.store.selectSnapshot(CrossBorderSelectors.isCrossBorderIB)
      ? this.crossborderIbAuditApiService.crossBorderIBAuditSaveResultsContext({
          correlationId: ctx.getState().auditCorrelationId,
          data: data,
          metadata: payload.metadata,
          createdAt: createdAt
        })
      : this.crossborderAuditApiService.auditSaveResultsContext({
          correlationId: ctx.getState().auditCorrelationId,
          data: data,
          metadata: payload.metadata,
          createdAt: createdAt
        });

    return service$.pipe(
      first(),
      switchMap(value => {
        return this.store.dispatch(new SetAuditCorrelationId(value.correlationId));
      })
    );
  }
}
