import { StateContext } from '@ngxs/store';
import { SharedStateModel } from '../shared.model';
import { SetSharedResultsLabels } from '../shared.action';

export class SetSharedResultsLabelsUseCases {
  public setLabels(context: StateContext<SharedStateModel>, { payload }: SetSharedResultsLabels) {
    const { staticLabels, rulesLabels, taxonomyVariables } = payload?.state;

    if (!taxonomyVariables || taxonomyVariables.length == 0) {
      return context.patchState({
        staticLabels,
        rulesLabels
      });
    }

    return context.patchState({
      staticLabels,
      rulesLabels,
      taxonomyVariables: taxonomyVariables
    });
  }
}
