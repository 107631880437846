import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { authInitializeGuardFn } from './shared/guards';
import { ConfigService } from './shared/services';
import { debounceTime, filter } from 'rxjs/operators';

const PORTAL_CLIENT_ID = { clientId: 'apps-web' };

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canMatch: [authInitializeGuardFn],
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
    data: PORTAL_CLIENT_ID
  },
  {
    path: 'profile',
    pathMatch: 'full',
    canMatch: [authInitializeGuardFn],
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
    data: PORTAL_CLIENT_ID
  },
  {
    path: '',
    loadChildren: () => import('./apps/apps-container.module').then(m => m.AppsContainerModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppsRoutingModule {
  constructor(
    private router: Router,
    private config: ConfigService
  ) {
    if (config.get('posthog.capture_pageview')) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          debounceTime(200)
        )
        .subscribe(event => {
          //@ts-ignore
          window.posthog?.capture('$pageview');
        });
    }
  }
}
