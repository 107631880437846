import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { first, switchMap } from 'rxjs/operators';
import { MarketingMaterialAuditApiService } from '../../../generated/apps-api';
import { SetAuditCorrelationId } from '../../shared/state/shared.action';
import { SharedStateModel } from '../../shared/state/shared.model';
import { mmAuditData } from '../utils/shared-results.utils';
import { SaveMMAudit } from './marketing-material-audit.action';

@State<MarketingMaterialAuditState>({
  name: 'marketingMaterialAudit'
})
@Injectable()
export class MarketingMaterialAuditState {
  constructor(
    private store: Store,
    private marketingMaterialAuditApiService: MarketingMaterialAuditApiService
  ) {}

  @Action(SaveMMAudit)
  public saveMMAudit(ctx: StateContext<SharedStateModel>, { payload }: SaveMMAudit) {
    const data = mmAuditData(this.store);

    return this.marketingMaterialAuditApiService
      .auditSaveResultsContext({
        correlationId: ctx.getState().auditCorrelationId,
        data: data,
        metadata: payload.metadata,
        createdAt: payload.generatedOn
      })
      .pipe(
        first(),
        switchMap(value => {
          return this.store.dispatch(new SetAuditCorrelationId(value.correlationId));
        })
      );
  }
}
