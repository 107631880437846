import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { filter } from 'rxjs/operators';
import { ErrorMessage } from '../../../../generated/apps-api';
import { LabelResolverContext, LabelsResolverService } from '../../services';
import { SetErrors } from '../../state/shared.action';
import { AppInfo } from '../../state/shared.model';
import { SharedSelectors } from '../../state/shared.selectors';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-shared-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss', './error.phone.component.scss', './error.tablet.component.scss']
})
export class ErrorComponent implements OnInit {
  constructor(
    private store: Store,
    private keycloakService: KeycloakService,
    private labelResolverService: LabelsResolverService
  ) {
    this.appInfo$ = this.store.select<AppInfo>(SharedSelectors.currentAppInfo);
  }

  public appInfo$: Observable<AppInfo>;
  public title: string;
  public description: string;
  public isLoggedIn: boolean;
  public labelContext: LabelResolverContext;
  public stepsToGoBack = 1;

  @Input()
  public modalMode = false;
  @Input()
  public showStartAgainButton = false;
  @Input()
  public showGoBackButton = true;
  @Output()
  public startAgain = new EventEmitter<void>();

  ngOnInit(): void {
    this.store
      .select(SharedSelectors.errors)
      .pipe(
        untilDestroyed(this),
        filter(error => !!error)
      )
      .subscribe(error => {
        this.title = error.titleLabelId || 'genericErrorApp';
        this.stepsToGoBack = error?.stepsToGoBack || 1;
        this.description = error.useMessageTextAsDescription
          ? this.buildMessageBasedDescription(error.messages)
          : this.labelResolverService.resolveValue(this.title, { field: 'description' });
        this.showGoBackButton = error?.showGoBackButton;
      });

    this.isLoggedIn = this.keycloakService.isLoggedIn();
  }

  private buildMessageBasedDescription(errorMessages: ErrorMessage[]) {
    this.labelContext =
      errorMessages?.length > 0
        ? {
            jurisdiction: errorMessages[0].jurisdiction,
            ruleSet: errorMessages[0].ruleSet
          }
        : undefined;

    return errorMessages
      .map(message =>
        this.labelResolverService.resolveReferences(message.text, {
          ruleSet: message.ruleSet,
          jurisdiction: message.jurisdiction
        })
      )
      .join('</br>');
  }

  goBack() {
    this.store
      .dispatch(new SetErrors())
      .subscribe(() => (this.modalMode ? undefined : history.go(-this.stepsToGoBack)));
  }

  public emitStartAgain(): void {
    this.store.dispatch(new SetErrors()).subscribe(() => this.startAgain.emit());
  }
}
