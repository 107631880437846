export enum MarketingMaterialAuditAction {
  SAVE_MM_AUDIT = '[Audit] saves a MM audit for BAM'
}

export class SaveMMAudit {
  public static readonly type = MarketingMaterialAuditAction.SAVE_MM_AUDIT;

  constructor(
    public payload: {
      generatedOn: string;
      metadata?: unknown;
    }
  ) {}
}
