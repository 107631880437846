import { RuleSet } from 'src/app/shared/state/shared.model';
import { Attribute } from '../../../generated/apps-api';
import { ClientOnboardingStateModel, ExtendedRole } from './client-onboarding.model';

export enum ClientOnboardingAction {
  LOAD_CONTEXT = '(Client Onboarding) [Load Context] Load application context',
  SET_CONTEXT_JURISDICTION = '(Client Onboarding) [Set Context Jurisdiction] Sets the jurisdiction to be used for all rule set contexts',
  START_AGAIN = '(Client Onboarding) [Start Again] Resets the Ruleset Contexts',

  SET_CURRENT_RULE_SET = '(Client Onboarding) [Set Current Rule Set] Sets the current rule set',
  PROCESS_CURRENT_RULE_SET_EVALUATION = '(Client Onboarding) [Process Current Rule Set Evaluation] Process current rule set evaluation',
  ROLLBACK_CURRENT_RULE_SET_EVALUATION = '(Client Onboarding) [Rollback Current Rule Set Evaluation] Rolls-back current rule set evaluation',

  LOAD_CLIENT_CLASSIFICATION_LABELS = '(Client Onboarding) [Load Client Classification Labels] Load "client classification" rule set labels',
  LOAD_OTHER_RULE_SETS_LABELS = '(Client Onboarding) [Load Other Rule Sets Labels] Load the other rule sets labels',

  UPDATE_ROLE = '(Client Onboarding) [Update Role] Updates a role',
  TOGGLE_ROLES_ACCORDIONS = '(Client Onboarding) [Toggle Roles Accordions] Expands/collapses all roles sections passed as parameter',

  SET_STATIC_RESULT = '(Client Onboarding) [Set Static Result] Sets the static result state'
}

export class LoadContext {
  public static readonly type = ClientOnboardingAction.LOAD_CONTEXT;

  constructor() {}
}

export class SetContextJurisdiction {
  public static readonly type = ClientOnboardingAction.SET_CONTEXT_JURISDICTION;

  constructor(public payload: { jurisdiction: string }) {}
}

export class SetCurrentRuleSet {
  public static readonly type = ClientOnboardingAction.SET_CURRENT_RULE_SET;

  constructor(public payload?: { ruleSet: RuleSet }) {}
}

export class RollbackCurrentRuleSetEvaluation {
  public static readonly type = ClientOnboardingAction.ROLLBACK_CURRENT_RULE_SET_EVALUATION;

  constructor(public payload?: { lastAttribute: Attribute }) {}
}

export class ProcessCurrentRuleSetEvaluation {
  public static readonly type = ClientOnboardingAction.PROCESS_CURRENT_RULE_SET_EVALUATION;

  constructor(public payload?: { newAttribute: Attribute }) {}
}

export class LoadClientClassificationLabels {
  public static readonly type = ClientOnboardingAction.LOAD_CLIENT_CLASSIFICATION_LABELS;

  constructor(public payload?: { jurisdiction: string }) {}
}

export class LoadOtherRuleSetsLabels {
  public static readonly type = ClientOnboardingAction.LOAD_OTHER_RULE_SETS_LABELS;

  constructor(public payload?: { jurisdiction: string }) {}
}

export class StartAgain {
  public static readonly type = ClientOnboardingAction.START_AGAIN;

  constructor() {}
}

export class UpdateRole {
  public static readonly type = ClientOnboardingAction.UPDATE_ROLE;

  constructor(public payload?: { role: ExtendedRole }) {}
}

export class ToggleRolesAccordions {
  public static readonly type = ClientOnboardingAction.TOGGLE_ROLES_ACCORDIONS;

  constructor(public payload?: { rolesIds: string[] }) {}
}

export class SetStaticResult {
  public static readonly type = ClientOnboardingAction.SET_STATIC_RESULT;

  constructor(public payload?: { state: ClientOnboardingStateModel }) {}
}
