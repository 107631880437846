// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppWindow } from 'src/config/AppConfig.model';

declare const window: AppWindow;

export const environment = {
  production: false,
  apiHost: window.__config.apiUrl,
  keycloak: {
    realm: window.__config.keycloakConfig.config.realm,
    clientId: window.__config.keycloakConfig.config.clientId,
    authServerUrl: window.__config.keycloakConfig.config.url,
    redirectUrl: window.__config.keycloakConfig.config.redirectUrl,
    onLoad: window.__config.keycloakConfig.initOptions.onLoad,
    'ssl-required': 'external',
    'public-client': true,
    'confidential-port': 0
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
