import { LabelsObject, SharedStateModel } from '../shared.model';
import { TaxonomyVariablesApiService } from '../../../../generated/apps-api';
import { StateContext } from '@ngxs/store';

export class LoadAllTaxonomyVariablesUseCases {
  constructor(private taxonomyVariablesApiService: TaxonomyVariablesApiService) {}

  public load(context: StateContext<SharedStateModel>, labels: LabelsObject) {
    const taxonomyVariables = this.getAllTaxonomyVariables(labels);
    return this.loadTaxonomyVariables(context, taxonomyVariables);
  }

  private loadTaxonomyVariables(context: StateContext<SharedStateModel>, taxonomyVariablesInLabels: string[]) {
    if (context.getState()?.allTaxonomyVariablesWithType?.length == 0 && taxonomyVariablesInLabels?.length > 0) {
      this.taxonomyVariablesApiService.getAllTaxonomyVariables().subscribe(res => {
        context.patchState({
          allTaxonomyVariablesWithType: res.variables.map(v => ({ identifier: v.identifier, type: v.type }))
        });
      });
    }
  }

  private getAllTaxonomyVariables(labels: LabelsObject): string[] {
    const results = JSON.stringify(labels).matchAll(/\${(.*?)}/g);
    return [...results].map(r => r?.[0]);
  }
}
