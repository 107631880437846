import { Selector } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { UserPreferences, WhiteLabelConfig } from '../../../generated/apps-api';
import {
  AppInfo,
  LabelsObject,
  SharedModelError,
  SharedResults,
  SharedStateModel,
  TaxonomyVariable
} from './shared.model';
import { SharedState } from './shared.state';

// required (https://github.com/ngxs/store/issues/541) otherwise when the state changes, all selectors
// will fire. The distinctUntilChanged won't work since we do a cloneDeep to fore the immutability
export class SharedSelectors {
  @Selector([SharedState.currentAppInfo])
  public static currentAppInfo(currentAppInfo: SharedStateModel['currentAppInfo']): AppInfo {
    return cloneDeep(currentAppInfo);
  }

  @Selector([SharedState.staticLabels])
  public static staticLabels(staticLabels: SharedStateModel['staticLabels']): LabelsObject {
    return cloneDeep(staticLabels);
  }

  @Selector([SharedState.rulesLabels])
  public static rulesLabels(rulesLabels: SharedStateModel['rulesLabels']): { [key: string]: LabelsObject } {
    return cloneDeep(rulesLabels);
  }

  @Selector([SharedState.taxonomyVariables])
  public static taxonomyVariables(taxonomyVariables: SharedStateModel['taxonomyVariables']): TaxonomyVariable[] {
    return cloneDeep(taxonomyVariables);
  }

  @Selector([SharedState.allTaxonomyVariablesWithType])
  public static allTaxonomyVariablesWithType(
    allTaxonomyVariablesWithType: SharedStateModel['allTaxonomyVariablesWithType']
  ): { identifier: string; type: string }[] {
    return cloneDeep(allTaxonomyVariablesWithType);
  }

  @Selector([SharedState.taxonomyVariablesContext])
  public static taxonomyVariablesContext(taxonomyVariablesContext: SharedStateModel['taxonomyVariables']): {
    [p: string]: string;
  } {
    const result: { [key: string]: string } = {};
    taxonomyVariablesContext.forEach(variable => (result[variable.name] = variable.value));
    return cloneDeep(result);
  }

  @Selector([SharedState.errors])
  public static errors(errors: SharedStateModel['errors']): SharedModelError {
    return cloneDeep(errors);
  }

  @Selector([SharedState.skipNextErrorHandling])
  public static skipNextErrorHandling(skipNextErrorHandling: SharedStateModel['skipNextErrorHandling']): boolean {
    return skipNextErrorHandling;
  }

  @Selector([SharedState.isLoaded])
  public static isLoaded(loaded: SharedStateModel['loaded']): boolean {
    return loaded;
  }

  @Selector([SharedState.userPreferences])
  public static isTestMode(userPreferences: SharedStateModel['userPreferences']): boolean {
    return userPreferences?.testMode || false;
  }

  @Selector([SharedState.jumpedSteps])
  public static jumpedSteps(jumpedSteps: SharedStateModel['jumpedSteps']): number[] {
    return cloneDeep(jumpedSteps);
  }

  @Selector([SharedState.whiteLabelConfig])
  public static whiteLabelConfig(whiteLabelConfig: SharedStateModel['whiteLabelConfig']): WhiteLabelConfig {
    return cloneDeep(whiteLabelConfig);
  }

  @Selector([SharedState.loggedIn])
  public static loggedIn(loggedIn: SharedStateModel['loggedIn']): boolean {
    return loggedIn;
  }

  @Selector([SharedState.hasExternalGrant])
  public static hasExternalGrant(hasExternalGrant: SharedStateModel['hasExternalGrant']): boolean {
    return hasExternalGrant;
  }

  @Selector([SharedState.sharedResults])
  public static sharedResults(sharedResults: SharedStateModel['sharedResults']): SharedResults {
    return cloneDeep(sharedResults);
  }

  @Selector([SharedState.userPreferences])
  public static userPreferences(userPreferences: SharedStateModel['userPreferences']): UserPreferences {
    return cloneDeep(userPreferences);
  }

  @Selector([SharedState.userPreferences])
  public static preferredLegalEntityId(userPreferences: SharedStateModel['userPreferences']): string {
    return userPreferences?.preferredLegalEntityId;
  }

  @Selector([SharedState.userPreferences])
  public static preferredLanguage(userPreferences: SharedStateModel['userPreferences']): string {
    return userPreferences?.preferredLanguage;
  }
}
