import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Pipe({
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private keycloak: KeycloakService
  ) {}

  async transform(src: string): Promise<string> {
    const token = this.keycloak.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const imageBlob = await this.http.get(src, { headers, responseType: 'blob' }).toPromise();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });
  }
}
