import { LabelsObject, SharedStateModel, TaxonomyVariable } from '../shared.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  TaxonomyVariableIdentifier,
  TaxonomyVariablesApiService,
  TaxonomyVariablesResponse
} from '../../../../generated/apps-api';
import { v4 as uuid } from 'uuid';
import { distinct } from '../../utils/arrays.utils';
import { TaxonomyVariablesContextOptions } from '../../model/TaxonomyVariablesContextOptions';
import { VariablesUtils } from '../../variables.utils';
import { StateContext } from '@ngxs/store';
import { TaxonomyVariablesMapper } from '../../mappers/taxonomy-variables-mapper';
import { cloneDeep, findIndex } from 'lodash';

export class ResolveTaxonomyVariablesUseCases {
  constructor(private taxonomyVariablesApiService: TaxonomyVariablesApiService) {}

  public resolveVariables(
    context: StateContext<SharedStateModel>,
    labels: LabelsObject,
    options: TaxonomyVariablesContextOptions
  ) {
    const taxonomyVariables = this.getAllTaxonomyVariables(labels);
    return this.loadTaxonomyVariables(context, taxonomyVariables, options);
  }

  private loadTaxonomyVariables(
    context: StateContext<SharedStateModel>,
    taxonomyVariablesInLabels: string[],
    options: TaxonomyVariablesContextOptions
  ): Observable<TaxonomyVariable[]> {
    const allTaxonomyVariablesWithType = context.getState().allTaxonomyVariablesWithType;
    const variablesWithMetadata = this.enrichVariablesWithMetadata(
      taxonomyVariablesInLabels,
      options,
      allTaxonomyVariablesWithType
    );
    return this.taxonomyVariablesApiService
      .resolveTaxonomyVariables({
        variablesIdentifiers: variablesWithMetadata
      })
      .pipe(
        map((response: TaxonomyVariablesResponse) => {
          const existingVariables = cloneDeep(context.getState().taxonomyVariables) || [];
          const taxonomyVariables = this.distinct(
            TaxonomyVariablesMapper.mapToTaxonomyVariables(response.variableResponseList, variablesWithMetadata),
            existingVariables
          );
          context.patchState({
            taxonomyVariables: taxonomyVariables
          });
          return taxonomyVariables;
        })
      );
  }

  private getAllTaxonomyVariables(labels: LabelsObject): string[] {
    const results = JSON.stringify(labels).matchAll(/\${(.*?)}/g);
    return [...results].map(r => r?.[0]);
  }

  private enrichVariablesWithMetadata(
    labels: string[],
    options: TaxonomyVariablesContextOptions,
    allTaxonomyVariablesWithType: {
      identifier: string;
      type: string;
    }[]
  ): TaxonomyVariableIdentifier[] {
    let result: TaxonomyVariableIdentifier[] = [];

    labels.filter(distinct).forEach(label => {
      const variableType = allTaxonomyVariablesWithType.find(
        v => v.identifier.toLowerCase() === label.toLowerCase()
      )?.type;
      // || label.toLowerCase().startsWith(VariablesUtils.LegalEntityVariableStartsBy)) => this code it to be retro compatible with old version (shared results, bal)
      if (
        variableType === VariablesUtils.LegalEntityType ||
        label.toLowerCase().startsWith(VariablesUtils.LegalEntityVariableStartsBy)
      ) {
        options?.legalEntitiesIds?.forEach(legalEntityId =>
          result.push({ identifier: label, correlationId: uuid(), options: { legalEntityId: legalEntityId } })
        );
      } else if (variableType === VariablesUtils.InstrumentType) {
        result.push({
          identifier: label,
          correlationId: uuid(),
          options: {
            isin: options.isin,
            country: options.country,
            providerId: options.providerId,
            organizationId: options.organizationId
          }
        });
      } else {
        result.push({ identifier: label, correlationId: uuid() });
      }
    });
    return result;
  }

  public distinct(newVariables: TaxonomyVariable[], existingVariables: TaxonomyVariable[]): TaxonomyVariable[] {
    const result: TaxonomyVariable[] = cloneDeep(existingVariables || []);

    newVariables.forEach(nv => {
      let existingVariable;
      //Find if is LE
      const existingLegalEntityVariable = result.find(
        ev =>
          nv.options?.legalEntityId &&
          ev.identifier == nv.identifier &&
          ev.options?.legalEntityId === nv.options.legalEntityId
      );
      if (existingLegalEntityVariable) existingVariable = existingLegalEntityVariable;
      //Find if is Instrument
      const existingInstrumentVariable = result.find(
        ev => nv.options?.country && ev.identifier == nv.identifier && ev.options?.country === nv.options.country
      );
      if (existingInstrumentVariable) existingVariable = existingInstrumentVariable;
      //Find other use cases
      const existingGeneralVariable = result.find(ev => !ev.options && ev.identifier == nv.identifier);
      if (existingGeneralVariable) existingVariable = existingGeneralVariable;

      if (!existingVariable) {
        //add new
        result.push(nv);
      } else {
        //replace existent
        const index = findIndex(result, existingVariable);
        result[index] = nv;
      }
    });

    return result;
  }
}
