import { Selector } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import {
  CrossBorderAction,
  CrossBorderAttribute,
  CrossBorderEvaluationInformation,
  CrossBorderProduct,
  PortfolioEntry,
  RuleSetInfo
} from '../../../generated/apps-api';
import { distinct } from '../../shared/utils/arrays.utils';
import { CBGlobalConstants } from '../cross-border.globalConstants';
import { CrossBorderInstrumentsStateModel } from './cross-border-instruments.model';
import { CrossBorderInstrumentsState } from './cross-border-instruments.state';
import { CrossBorderQuestion, ExtendedProduct } from './cross-border.model';
import { getNextStepNumber, getStepNumber, stepContainsQuestion } from './financial-services-steps.utils';

// required (https://github.com/ngxs/store/issues/541) otherwise when the state changes, all selectors
// will fire. The distinctUntilChanged won't work since we do a cloneDeep to force the immutability
export class CrossBorderInstrumentsSelectors {
  // @ts-ignore
  @Selector([CrossBorderInstrumentsState.fundsFlowHasRun])
  public static fundsFlowHasRun(
    results: CrossBorderInstrumentsStateModel['results'],
    questions: CrossBorderInstrumentsStateModel['questions']
  ): boolean {
    return !!results || !!questions?.length;
  }

  @Selector([CrossBorderInstrumentsState.questions])
  public static questions(questions: CrossBorderInstrumentsStateModel['questions']): CrossBorderQuestion[] {
    return cloneDeep(questions);
  }

  @Selector([CrossBorderInstrumentsState.instrumentsRuleSets])
  public static instrumentsRuleSets(
    instrumentsRuleSets: CrossBorderInstrumentsStateModel['instrumentsRuleSets']
  ): RuleSetInfo.RuleSetTypeEnum[] {
    return cloneDeep(instrumentsRuleSets);
  }

  @Selector([CrossBorderInstrumentsState.instrumentsProducts])
  public static instrumentsProducts(
    instrumentsProducts: CrossBorderInstrumentsStateModel['instrumentsProducts']
  ): ExtendedProduct[] {
    return cloneDeep(instrumentsProducts);
  }

  @Selector([CrossBorderInstrumentsState.questions, CrossBorderInstrumentsState.currentStepNumber])
  public static currentStepQuestions(
    questions: CrossBorderInstrumentsStateModel['questions'],
    currentStep: CrossBorderInstrumentsStateModel['currentStep']
  ): CrossBorderQuestion[] {
    const regpropsPerStepIndex = currentStep - 1;

    return cloneDeep(questions.filter(q => stepContainsQuestion(regpropsPerStepIndex, q)));
  }

  @Selector([CrossBorderInstrumentsState.attributes])
  public static attributes(attributes: CrossBorderInstrumentsStateModel['attributes']): CrossBorderAttribute[] {
    return cloneDeep(attributes);
  }

  @Selector([CrossBorderInstrumentsState.results])
  public static results(results: CrossBorderInstrumentsStateModel['results']): { [key: string]: CrossBorderAction[] } {
    return cloneDeep(results);
  }

  @Selector([CrossBorderInstrumentsState.results])
  public static hasResults(results: CrossBorderInstrumentsStateModel['results']): boolean {
    return !!cloneDeep(results);
  }

  @Selector([CrossBorderInstrumentsState.questions])
  public static firstStepNumber(questions: CrossBorderInstrumentsStateModel['questions']): number {
    return getStepNumber(questions[0]?.property);
  }

  @Selector([CrossBorderInstrumentsState.questions, CrossBorderInstrumentsState.currentStepNumber])
  public static nextStepNumber(
    questions: CrossBorderInstrumentsStateModel['questions'],
    currentStep: CrossBorderInstrumentsStateModel['currentStep']
  ): number {
    return getNextStepNumber(questions, currentStep, CBGlobalConstants.MAX_INSTRUMENTS_STEPS);
  }

  @Selector([CrossBorderInstrumentsState.currentStepNumber])
  public static currentStepNumber(currentStep: CrossBorderInstrumentsStateModel['currentStep']): number {
    return currentStep;
  }

  @Selector([CrossBorderInstrumentsState.evaluationInformation])
  public static evaluationInformation(
    evaluationInformation: CrossBorderInstrumentsStateModel['evaluationInformation']
  ): CrossBorderEvaluationInformation[] {
    return evaluationInformation ? [cloneDeep(evaluationInformation)] : [];
  }

  @Selector([CrossBorderInstrumentsState.showOverlay])
  public static showOverlay(showOverlay: CrossBorderInstrumentsStateModel['showOverlay']): boolean {
    return showOverlay;
  }

  @Selector([CrossBorderInstrumentsState.usePortfolio])
  public static usePortfolio(usePortfolio: CrossBorderInstrumentsStateModel['usePortfolio']): boolean {
    return usePortfolio;
  }

  @Selector([CrossBorderInstrumentsState.portfolioEntries])
  public static portfolioEntries(
    portfolioEntries: CrossBorderInstrumentsStateModel['portfolioEntries']
  ): PortfolioEntry[] {
    return cloneDeep(portfolioEntries);
  }

  @Selector([CrossBorderInstrumentsState.portfolioEntries])
  public static portfolioProductTypes(
    portfolioEntries: CrossBorderInstrumentsStateModel['portfolioEntries']
  ): string[] {
    return portfolioEntries?.map(e => e.productType).filter(distinct);
  }

  @Selector([CrossBorderInstrumentsState.portfolioEntries])
  public static hasLoadedPortfolio(portfolioEntries: CrossBorderInstrumentsStateModel['portfolioEntries']): boolean {
    return portfolioEntries !== undefined;
  }
}
