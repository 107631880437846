import { inject, Injectable, InjectionToken } from '@angular/core';
import { AgentConfigOptions, ApmBase } from '@elastic/apm-rum';
import { ApmService } from '@elastic/apm-rum-angular';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { get } from 'lodash-es';

import { AnalyticsConfig } from './config.model';
import { ConfigService } from './config.service';
import { UserService } from '../../portal/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private apm: ApmBase;

  constructor(
    private apmService: ApmService,
    private keycloakService: KeycloakService,
    private baseConfigService: ConfigService,
    private userService: UserService
  ) {}

  public initialize() {
    this.apm = this.initApm();
    this.initUserInfoCapture();
  }

  protected initApm(): ApmBase | undefined {
    const apmConfig = this.baseConfigService.getApmConfig();
    if (apmConfig?.active) {
      const options: AgentConfigOptions = {
        active: false,
        environment: 'local',
        ...apmConfig
      };

      return this.apmService.init(options);
    }
    return undefined;
  }

  protected initUserInfoCapture() {
    const analytics: AnalyticsConfig = this.baseConfigService.getAnalyticsConfig();

    if (analytics.active && analytics.trackUser) {
      this.keycloakService.keycloakEvents$.pipe(untilDestroyed(this)).subscribe(event => {
        switch (event.type) {
          case KeycloakEventType.OnAuthSuccess:
            const keycloak = this.keycloakService.getKeycloakInstance();
            const id = get(keycloak, 'tokenParsed.uid');
            const email = get(keycloak, 'tokenParsed.email');

            if (id || email) {
              this.apm?.setUserContext({ id, email });
            }
            this.userService.reportUserInfo();
            break;
          case KeycloakEventType.OnAuthLogout:
            this.userService.resetUserInfo();
            break;
          default:
            break;
        }
      });
    }
  }
}

export const ANALYTICS_SERVICE_TOKEN = new InjectionToken<AnalyticsService>('AnalyticsService', {
  providedIn: 'root',
  factory: () =>
    new AnalyticsService(inject(ApmService), inject(KeycloakService), inject(ConfigService), inject(UserService))
});
