import { Store } from '@ngxs/store';
import { SharedSelectors } from 'src/app/shared/state/shared.selectors';
import { MarketingMaterialStateModel } from '../state';
import { MarketingMaterialSelectors } from '../state/marketing-material.selectors';

export const buildMMStaticResultState: (store: Store) => () => Partial<MarketingMaterialStateModel> = store => {
  return () => {
    return {
      distributionCountries: store.selectSnapshot(MarketingMaterialSelectors.distributionCountries),
      results: store.selectSnapshot(MarketingMaterialSelectors.filteredResults),
      labelsWithVariables: store.selectSnapshot(MarketingMaterialSelectors.labelsWithVariables),
      legalEntities: store.selectSnapshot(MarketingMaterialSelectors.selectedLegalEntities),
      selectedLegalEntitiesIdsWithResults: store.selectSnapshot(
        MarketingMaterialSelectors.selectedLegalEntitiesIdsWithResults
      ),
      selectedDistributionCountries: store.selectSnapshot(MarketingMaterialSelectors.selectedDistributionCountries),
      selectedDistributionCountriesWithResults: store.selectSnapshot(
        MarketingMaterialSelectors.selectedDistributionCountriesWithResults
      ),
      selectedLegalEntitiesMode: store.selectSnapshot(MarketingMaterialSelectors.selectedLegalEntitiesMode),
      disclaimerLanguages: {
        selectedDisclaimerLanguages: store.selectSnapshot(MarketingMaterialSelectors.selectedDisclaimerLanguages),
        supportedDisclaimerLanguages: store.selectSnapshot(MarketingMaterialSelectors.supportedDisclaimerLanguages)
      },
      ruleSetsInfo: store.selectSnapshot(MarketingMaterialSelectors.ruleSetsInfo),
      instruments: store.selectSnapshot(MarketingMaterialSelectors.instruments),
      questions: store.selectSnapshot(MarketingMaterialSelectors.questions),
      attributes: store.selectSnapshot(MarketingMaterialSelectors.attributes),
      applicableCustomActionTagsOptions: store.selectSnapshot(
        MarketingMaterialSelectors.applicableCustomActionTagsOptions
      ),
      applicablePerformanceDataOptions: store.selectSnapshot(
        MarketingMaterialSelectors.applicablePerformanceDataOptions
      ),
      customActionsTagValues: store.selectSnapshot(MarketingMaterialSelectors.customActionsTagValues),
      performanceDataValues: store.selectSnapshot(MarketingMaterialSelectors.performanceDataValues)
    };
  };
};

export const mmAuditData = (store: Store) => {
  return {
    ...buildMMStaticResultState(store)(),
    staticLabels: store.selectSnapshot(SharedSelectors.staticLabels),
    taxonomyVariables: store.selectSnapshot(SharedSelectors.taxonomyVariables),
    allTaxonomyVariablesWithType: store.selectSnapshot(SharedSelectors.allTaxonomyVariablesWithType),
    rulesLabels: store.selectSnapshot(SharedSelectors.rulesLabels)
  };
};
