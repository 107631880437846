import { Pipe, PipeTransform } from '@angular/core';
import { LabelResolverContext, LabelsResolverService } from '../services/labelsResolver.service';

@Pipe({
  name: 'resolveReferences'
})
export class ResolveReferencesPipe implements PipeTransform {
  constructor(protected labelsResolverService: LabelsResolverService) {}

  transform(value: string, context?: LabelResolverContext): string {
    return this.labelsResolverService.resolveReferences(value, context);
  }
}
