import {
  Label,
  MarketingMaterialInstrument,
  MarketingMaterialResults,
  MMLegalEntity,
  RuleSetsInfo,
  SupportedFootnotesAndDisclaimerLanguages
} from 'src/generated/apps-api';
import { MarketingMaterialAttribute } from 'src/generated/apps-api/model/marketingMaterialAttribute';
import { MarketingMaterialQuestion } from 'src/generated/apps-api/model/marketingMaterialQuestion';
import { MarketingMaterialExtendedQuestion } from '../models/marketingMaterialExtendedQuestion';
import {
  LegalEntityMode,
  MarketingMaterialStateModel,
  SelectedDisclaimerLanguage,
  SelectedLegalEntity
} from './marketing-material.model';
import { LabelsObject } from '../../shared/state/shared.model';

export enum MarketingMaterialAction {
  SET_DISTRIBUTION_COUNTRIES = '(MM) Set Distribution Countries',
  SET_SELECTED_DISTRIBUTION_COUNTRIES = '(MM) Set Selected Distribution Countries',
  SET_SELECTED_DISTRIBUTION_COUNTRIES_WITH_RESTULTS = '(MM) Set Selected Distribution Countries with Results',
  SET_LEGAL_ENTITIES = '(MM) Set Legal Entities',
  SET_SELECTED_LEGAL_ENTITIES = '(MM) Set Selected Legal Entities',
  SET_SELECTED_LEGAL_ENTITIES_MODE = '(MM) Set selected Legal Entities selection mode',
  SET_ATTRIBUTES = '(MM) Set Attributes',
  SET_ATTRIBUTE = '(MM) Set Single Attribute for Question',
  ADD_QUESTION = '(MM) Add Question',
  SET_QUESTIONS = '(MM) Set Questions',
  SET_RESULTS = '(MM) Set Results',
  SET_STATIC_RESULT = '(MM) Set Static Result',
  SET_FILTERS = '(MM) Set Filters',
  SET_SUPPORTED_DISCLAIMERS_LANGUAGES = '(MM) Set Suported Disclaimers and footnotes supported languages by country',
  SET_SELECTED_DISCLAIMERS_LANGUAGE = '(MM) Set Selected Disclaimers and footnotes language',
  SET_INSTRUMENT = '(MM) Set Instrument',
  SET_IS_INSTRUMENT_SEARCHABLE = '[Set Is Instrument Searchable] Sets the allowance of if user is allowed to user for Instruments',
  SET_LABELS_WITH_VARIABLES = '(MM) Set labels with variables'
}

export class SetDistributionCountries {
  public static readonly type = MarketingMaterialAction.SET_DISTRIBUTION_COUNTRIES;

  constructor(
    public payload?: {
      countries: string[];
      allCountries: string[];
    }
  ) {}
}

export class SetSelectedDistributionCountries {
  public static readonly type = MarketingMaterialAction.SET_SELECTED_DISTRIBUTION_COUNTRIES;

  constructor(public payload?: { countries: string[] }) {}
}

export class SetSelectedDistributionCountriesWithResults {
  public static readonly type = MarketingMaterialAction.SET_SELECTED_DISTRIBUTION_COUNTRIES_WITH_RESTULTS;

  constructor(public payload?: { countries: string[] }) {}
}

export class SetLegalEntities {
  public static readonly type = MarketingMaterialAction.SET_LEGAL_ENTITIES;

  constructor(public payload?: { legalEntities: MMLegalEntity[] }) {}
}

export class SetSelectedEntities {
  public static readonly type = MarketingMaterialAction.SET_SELECTED_LEGAL_ENTITIES;

  constructor(public payload?: { legalEntitiesIds: SelectedLegalEntity[] }) {}
}

export class SetSelectedLegalEntitiesMode {
  public static readonly type = MarketingMaterialAction.SET_SELECTED_LEGAL_ENTITIES_MODE;

  constructor(public payload?: { legalEntityMode: LegalEntityMode }) {}
}

export class SetAttributes {
  public static readonly type = MarketingMaterialAction.SET_ATTRIBUTES;

  constructor(public payload?: { attributes: MarketingMaterialAttribute[] }) {}
}

export class AddQuestion {
  public static readonly type = MarketingMaterialAction.ADD_QUESTION;

  constructor(public payload?: { question: MarketingMaterialExtendedQuestion }) {}
}

export class SetQuestions {
  public static readonly type = MarketingMaterialAction.SET_QUESTIONS;

  constructor(public payload?: { questions: MarketingMaterialQuestion[] }) {}
}

export class SetResults {
  public static readonly type = MarketingMaterialAction.SET_RESULTS;

  constructor(
    public payload?: {
      results: MarketingMaterialResults;
      ruleSetsInfo: RuleSetsInfo[];
      generatedOn: string;
      generatedByFullName: string;
    }
  ) {}
}

export class SetLabelsWithVariables {
  public static readonly type = MarketingMaterialAction.SET_LABELS_WITH_VARIABLES;

  constructor(
    public payload?: {
      ruleSetLabels: { [key: string]: LabelsObject };
    }
  ) {}
}

export class SetStaticResult {
  public static readonly type = MarketingMaterialAction.SET_STATIC_RESULT;

  constructor(public payload?: { state: MarketingMaterialStateModel }) {}
}

export class SetFilters {
  public static readonly type = MarketingMaterialAction.SET_FILTERS;

  constructor(public payload?: { customActionsTagValues: string[]; performanceDataValues: string[] }) {}
}

export class SetSupportedDisclaimerLanguages {
  public static readonly type = MarketingMaterialAction.SET_SUPPORTED_DISCLAIMERS_LANGUAGES;

  constructor(
    public payload?: {
      supportedFootnotesAndDisclaimerLanguages: Array<SupportedFootnotesAndDisclaimerLanguages>;
    }
  ) {}
}

export class SetSelectedDisclaimerLanguages {
  public static readonly type = MarketingMaterialAction.SET_SELECTED_DISCLAIMERS_LANGUAGE;

  constructor(public payload?: { selectedDisclaimerLanguages: SelectedDisclaimerLanguage[] }) {}
}

export class SetInstrument {
  public static readonly type = MarketingMaterialAction.SET_INSTRUMENT;

  constructor(public payload?: { instrument: MarketingMaterialInstrument }) {}
}

export class SetIsInstrumentSearchable {
  public static readonly type = MarketingMaterialAction.SET_IS_INSTRUMENT_SEARCHABLE;

  constructor(public payload: { isInstrumentSearchable: boolean }) {}
}
