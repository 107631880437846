import { TaxonomyVariableIdentifier, VariableResponse } from 'src/generated/apps-api';
import { TaxonomyVariable } from '../state/shared.model';

export class TaxonomyVariablesMapper {
  static mapToTaxonomyVariables(
    taxonomyVarialbes: VariableResponse[],
    variablesWithMetadata: TaxonomyVariableIdentifier[]
  ) {
    const variables: TaxonomyVariable[] = [];
    taxonomyVarialbes?.forEach(v =>
      variables.push({
        identifier: v.identifier,
        value: v.value,
        name: v.name,
        correlationId: v.correlationId,
        options: variablesWithMetadata?.find(l => l?.correlationId == v?.correlationId)?.options
      })
    );

    return variables;
  }
}
