<div class="dropdown-wrapper" [ngClass]="themeClass">
  <ng-select
    #ngSelect
    bindValue="value"
    appendTo="body"
    [class.country]="countrySelection"
    [searchable]="searchable"
    [items]="orderedOptions"
    [dropdownPosition]="'auto'"
    [searchFn]="search()"
    [clearSearchOnAdd]="true"
    [closeOnSelect]="!multiple"
    [clearable]="false"
    [selectOnTab]="true"
    [loading]="isLoading"
    [multiple]="multiple"
    [placeholder]="placeholder ?? (countrySelection ? 'selectCountryPlaceholder' : 'selectOptionPlaceholder') | toLabel"
    [(ngModel)]="value"
    [disabled]="disabled"
    [notFoundText]="multiSelectNotFoundText | toLabel"
    (search)="onSearchInputChanged($event)"
    (change)="onValueChanged($event)"
    (add)="selectAllToggle ? addToggleLogic($event) : undefined"
    (remove)="selectAllToggle ? removeToggleLogic($event) : undefined"
    (close)="onClose()"
  >
    <ng-template ng-label-tmp let-option="item">
      <div
        *ngIf="option.value !== selectAllToggleOption.value"
        class="option-container"
        [class.multiple]="multiple"
        [class.disabled]="disabled"
      >
        @if (countrySelection && !multiple) {
          <div class="option-icon flag flag-{{ getAlpha2(option.countryCode) }}"></div>
        }

        <span class="option-label">
          {{ option.name || (option.nameLabelId | toLabel: { ruleSet, jurisdiction }) }}
        </span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-option="item">
      <div class="option-container" [ngClass]="{ disabled: disabled }">
        <div class="left-content">
          <div *ngIf="multiple" class="checkbox"></div>
          <div *ngIf="countrySelection" class="option-icon flag flag-{{ getAlpha2(option.countryCode) }}"></div>
          <span class="option-label">
            {{ option.name || (option.nameLabelId | toLabel: { ruleSet: ruleSet, jurisdiction: jurisdiction }) }}
          </span>
          <app-tooltip
            *ngIf="
              ((option.nameLabelId && showOptionTooltip) || optionWarnings?.[option.value]) &&
              option.value !== selectAllToggleOption.value
            "
            [helpText]="
              optionWarnings?.[option.value] || option.nameLabelId
                | toLabel: { field: 'description', ruleSet: ruleSet, jurisdiction: jurisdiction }
            "
            [appShowTooltipModal]="{
              labelId: optionWarnings?.[option.value] || option.nameLabelId,
              ruleSet: ruleSet,
              jurisdiction: jurisdiction,
              contentProviderId: contentProviderId,
              modalOpenCallback: modalOpenFunction
            }"
            [isAlert]="!!optionWarnings?.[option.value]"
            [darkTheme]="darkTheme"
          ></app-tooltip>
        </div>
        <span class="option-hint" *ngIf="option.hint">{{ option.hint | toLabel }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
