<div class="modal-container">
  <div class="header-container">
    <i *ngIf="showBackButton" class="arrow" [inlineSVG]="'assets/left-arrow.svg'" (click)="backButtonClick.emit()"></i>

    <div class="title" (click)="clickTitle()">{{ title | toLabel: labelResolverContext }}</div>
    <i
      class="close"
      aria-label="Close"
      labelId="closeButton"
      [inlineSVG]="'assets/modal-cross.svg'"
      (click)="close()"
      *ngIf="showCloseButton"
    ></i>
  </div>
  <ng-container *ngIf="(errors$ | async) === null; else errorSection">
    <ng-content></ng-content>
  </ng-container>
</div>
<ng-template #errorSection>
  <app-shared-error
    [modalMode]="true"
    [showStartAgainButton]="showStartAgainButtonOnError"
    (startAgain)="clickStartAgain()"
  ></app-shared-error>
</ng-template>
