import { RuleSet } from 'src/app/shared/state/shared.model';
import { Attribute } from 'src/generated/apps-api';
import { DataTransferStateModel, RuleSetContext } from './data-transfer.model';

export enum DataTransferAction {
  LOAD_CONTEXT = '(Data Transfer) [Load Context] Load application context',
  SET_GENERAL_ATTRIBUTE = '(Data Transfer) [General Attribute] Set attribute',
  SET_CURRENT_JURISDICTION = '(Data Transfer) [Current Jurisdiction] Set Current jurisdiction',
  SET_MAIN_FLOW_CONTEXT = '(Data Transfer) [Main flow Context] Set main flow Context',
  SET_SENDER_RECEIVER_JURISDICTIONS = '(Data Transfer) [Jurisdictions] Set sender and receiver jurisdictions',
  SET_SENDER_RECEIVER_AVAILABLE_JURISDICTIONS = '(Data Transfer) [Jurisdictions] Set sender and receiver available jurisdictions',
  LOAD_TERRITORIAL_SCOPE_LABELS = '(Data Transfer) [Load Labels] Load Territorial Scope Jurisdiction labels',
  START_AGAIN = '(Data Transfer) [Start Again] Resets the Ruleset Contexts',
  SET_CURRENT_RULESET = '(Data Transfer) [Rule Set] Set Current Rule Set',
  SET_DATA_CLASSIFICATION_CONTEXT = '(Data Transfer) [Data Classification] Set Data Classification context',
  SET_CURRENT_SCOPE = '(Data Transfer) [Current Scope] Set Current Scope',
  INIT_MAIN_FLOW_JURISDICTION = '(Data Transfer) [Main flow jurisdiciton] Init Main Flow Jurisdiction',
  CLEAR_FOLLOWING_RULE_SETS = '(Data Transfer) [Rule Sets] Clear following Rule Sets',
  INIT_NEW_JURISDICTION_FLOW = '(Data Transfer) [New Jurisdiction Flow] Init new Jurisdiction Flow',
  SET_DATA_TRANSFER_CONDITION_CONTEXT = '(Data Transfer) [Data Transer condition Context] Set Context',
  LOAD_DATA_TRANSFER_LABELS = '(Data Transfer) [Data Transfer] Load labels',
  CLEAR_MAIN_FLOW_CONTEXT = '(Data Transfer) [Clear Main Flow Context] Clear Main Flow Context',
  SET_STATIC_RESULT = '(Data Transfer) [Set Static Result] Sets the static result state',
  ROLL_BACK_CONTEXT = '(Data Transfer) [Main flow context] Roll back context'
}

export class LoadContext {
  public static readonly type = DataTransferAction.LOAD_CONTEXT;

  constructor() {}
}

export class SetGeneralAttribute {
  public static readonly type = DataTransferAction.SET_GENERAL_ATTRIBUTE;

  constructor(public payload?: { newAttribute: Attribute }) {}
}

export class SetCurrentJurisdiction {
  public static readonly type = DataTransferAction.SET_CURRENT_JURISDICTION;

  constructor(public payload?: string) {}
}

export class SetMainFlowContext {
  public static readonly type = DataTransferAction.SET_MAIN_FLOW_CONTEXT;

  constructor(public payload?: RuleSetContext) {}
}

export class SetDataClassContext {
  public static readonly type = DataTransferAction.SET_MAIN_FLOW_CONTEXT;

  constructor(public payload?: RuleSetContext) {}
}

export class InitMainFlowJurisdiction {
  public static readonly type = DataTransferAction.INIT_MAIN_FLOW_JURISDICTION;

  constructor(public payload?: string) {}
}

export class ClearMainFlowContext {
  public static readonly type = DataTransferAction.CLEAR_MAIN_FLOW_CONTEXT;

  constructor() {}
}

export class SetDataClassificationContext {
  public static readonly type = DataTransferAction.SET_DATA_CLASSIFICATION_CONTEXT;

  constructor(public payload?: RuleSetContext) {}
}

export class LoadTerritorialScopeLabels {
  public static readonly type = DataTransferAction.LOAD_TERRITORIAL_SCOPE_LABELS;

  constructor(public payload?: { jurisdiction: string }) {}
}

export class ClearFollowingRuleSets {
  public static readonly type = DataTransferAction.CLEAR_FOLLOWING_RULE_SETS;

  constructor(public payload?: { jurisdiction?: string; followingCountries?: string[] }) {}
}

export class RollBackContext {
  public static readonly type = DataTransferAction.ROLL_BACK_CONTEXT;

  constructor(public payload: { changedAttribute: string }) {}
}

export class LoadDataTransferLabels {
  public static readonly type = DataTransferAction.LOAD_DATA_TRANSFER_LABELS;

  constructor() {}
}

export class SetSenderReceiverJurisdictions {
  public static readonly type = DataTransferAction.SET_SENDER_RECEIVER_JURISDICTIONS;

  constructor(public payload?: { senderJurisdictions?: string[]; receiverJurisdictions?: string[] }) {}
}

export class SetSenderReceiverAvailableJurisdictions {
  public static readonly type = DataTransferAction.SET_SENDER_RECEIVER_AVAILABLE_JURISDICTIONS;

  constructor(
    public payload?: { senderAvailableJurisdictions?: string[]; receiverAvailableJurisdictions?: string[] }
  ) {}
}

export class StartAgain {
  public static readonly type = DataTransferAction.START_AGAIN;

  constructor() {}
}

export class SetCurrentRuleSet {
  public static readonly type = DataTransferAction.SET_CURRENT_RULESET;

  constructor(public payload?: { ruleSet: RuleSet }) {}
}

export class SetCurrentScope {
  public static readonly type = DataTransferAction.SET_CURRENT_SCOPE;

  constructor(public payload?: 'sender' | 'receiver') {}
}

export class InitNewJurisdictionFlow {
  public static readonly type = DataTransferAction.INIT_NEW_JURISDICTION_FLOW;

  constructor(public payload: { subjectOfInterestType: 'sender' | 'receiver'; jurisdiction: string }) {}
}

export class SetStaticResult {
  public static readonly type = DataTransferAction.SET_STATIC_RESULT;

  constructor(public payload?: { state: DataTransferStateModel }) {}
}
