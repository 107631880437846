import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  constructor() {}

  @Input() helpText = '';
  @Input() iconText: string;
  @Input() darkTheme = false;
  @Input() isCopy = false;
  @Input() isAlert = false;
  @Input() showTooltipModal = false;
  @Input() whiteTooltip = false;
  @Input() size: 'default' | 'small' | 'large' = 'default';
  @Input() type: 'i' | '!' = 'i';
  @Input() showHoverAction: boolean;
  @Input() disabled = false;
  @HostBinding('class.disabled') disabledClass = false;

  public isHidden = true;
  public withEllipsis = false;

  @ViewChild('iconRef')
  private iconRef: ElementRef;
  @ViewChild('tooltipRef')
  private tooltipRef: ElementRef;

  ngOnInit(): void {
    this.disabledClass = this.disabled;
  }

  onMouseEnter(): void {
    if (this.tooltipRef && this.iconRef) {
      const iconPosition = this.iconRef.nativeElement.getBoundingClientRect();
      this.tooltipRef.nativeElement.style.top = iconPosition.top + 22 + 'px';
      this.tooltipRef.nativeElement.style.left = iconPosition.left + 'px';
      this.isHidden = false;
      setTimeout(() => {
        const tooltipPosition = this.tooltipRef.nativeElement.getBoundingClientRect();
        const tooltipChildPosition = this.tooltipRef.nativeElement.lastElementChild?.getBoundingClientRect();
        const heightDifference = tooltipPosition.height - tooltipChildPosition.height;
        this.withEllipsis = tooltipChildPosition?.bottom - heightDifference / 2 > tooltipPosition.bottom;
      }, 10);
    } else {
      if (this.showHoverAction !== undefined) this.isHidden = !this.showHoverAction;
    }
  }

  onMouseLeave(): void {
    this.isHidden = true;
  }

  get themeClass() {
    return (
      (this.darkTheme ? 'theme-dark' : 'theme-light') +
      (this.whiteTooltip ? ' white-tooltip' : '') +
      (this.isAlert ? ' alert' : '')
    );
  }
}
