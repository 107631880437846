import { AfterViewInit, Directive, ElementRef, Input, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { Products } from '../../../state/shared.model';
import { first } from 'rxjs/operators';
import { LabelsResolverModalComponent } from '../modal/labels-resolver-modal.component';
import { HoverTooltipComponent } from '../../hover-tooltip/hover-tooltip.component';
import { LabelResolverContext, LabelsResolverService } from '../../../services/labelsResolver.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@UntilDestroy()
@Directive({
  selector: '[appLabelLinkClickListener]'
})
export class LabelLinkClickListenerDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private vc: ViewContainerRef,
    private labelsResolverService: LabelsResolverService,
    private modal: NgbModal
  ) {
    this.tooltipComponent = vc.createComponent(HoverTooltipComponent).instance;
  }

  @Input() refreshListeners$: Subject<void>;
  @Input() context: LabelResolverContext;

  @Input() labelClick: (termId: string) => void = this.resolvedLabelClick;
  @Input() labelHover: ($event: { termId: string; top: number; left: number }) => void = this.showTooltip;
  @Input() labelOut: () => void = this.hideTooltip;

  private tooltipComponent: HoverTooltipComponent;

  ngAfterViewInit(): void {
    this.addListenersToTerms();
    if (this.refreshListeners$) {
      this.refreshListeners$.pipe(untilDestroyed(this)).subscribe(() => this.addListenersToTerms());
    }
  }

  private resolvedLabelClick(termId: string) {
    const context: LabelResolverContext = {
      jurisdiction: this.context.jurisdiction,
      ruleSet: Products.MARKETING_MATERIAL_DOCUMENT_CHECK,
      contentProviderId: this.context.contentProviderId,
      priorityLanguage: this.context.priorityLanguage,
      parameters: this.context?.parameters
    };
    this.labelsResolverService
      .resolveLabelId(termId, context)
      .pipe(first())
      .subscribe(result => {
        if (result) {
          const modalRef = this.modal.open(LabelsResolverModalComponent, {
            centered: true,
            animation: false,
            windowClass: 'tooltip-modal'
          });

          modalRef.componentInstance.content = result.description;
          modalRef.componentInstance.ruleSet = this.context.ruleSet;
          modalRef.componentInstance.jurisdiction = this.context.jurisdiction;
          modalRef.componentInstance.title = result.title;
          modalRef.componentInstance.contentProviderId = this.context.contentProviderId;
          modalRef.componentInstance.priorityLanguage = this.context.priorityLanguage;
          modalRef.componentInstance.contextParameters = this.context.parameters;
        }
      });
  }

  private showTooltip($event: { termId: string; top: number; left: number }) {
    this.tooltipComponent.showTooltipAtPosition(
      $event.top,
      $event.left,
      '[[' + $event.termId + ':definition]]',
      this.context
    );
  }

  private hideTooltip() {
    this.tooltipComponent.hideTooltip();
  }

  private addListenersToTerms() {
    const terms: NodeListOf<HTMLElement> = this.el.nativeElement.querySelectorAll('.term-resolved');

    terms?.forEach(term => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      term.onmouseover = (event: any) => {
        event.stopPropagation();
        const termId = event.target.getAttribute('data-id');
        if (this.labelHover) {
          this.labelHover({
            termId,
            top: event.clientY,
            left: event.clientX
          });
        } else {
          this.showTooltip({
            termId,
            top: event.clientY,
            left: event.clientX
          });
        }
      }; // eslint-disable-next-line @typescript-eslint/no-explicit-any
      term.onmouseout = (event: any) => {
        event.stopPropagation();
        this.hideTooltip();
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      term.onclick = (event: any) => {
        event.stopPropagation();
        const termId = event.target.getAttribute('data-id');
        if (this.labelOut) {
          this.labelOut();
        } else {
          this.hideTooltip();
        }
        if (this.labelClick) {
          this.labelClick(termId);
        } else {
          this.resolvedLabelClick(termId);
        }
      };
    });
  }
}
