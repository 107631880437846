export enum CrossborderAuditAction {
  SAVE_CB_AUDIT = '[Audit] saves a CB audit for BAM'
}

export class SaveCBAudit {
  public static readonly type = CrossborderAuditAction.SAVE_CB_AUDIT;

  constructor(
    public payload: {
      generatedOn: string;
      metadata?: unknown;
    }
  ) {}
}
