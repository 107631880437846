import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { cloneDeep, forOwn, isArray } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Label } from '../../../generated/apps-api';
import { AddRuleSetLabels } from '../state/shared.action';
import { LabelsObject } from '../state/shared.model';
import { SharedSelectors } from '../state/shared.selectors';
import { SharedState } from '../state/shared.state';

export interface LabelResolverContext {
  field?: 'title' | 'description';
  jurisdiction?: string | string[];
  ruleSet?: string;
  parameters?: { [key: string]: string };
  defaultValue?: string;
  discardReplaceReferences?: boolean;
  contentProviderId?: string;
  // Use if the resolver should add a <span> wrapping the resolution with class 'term-resolved' (for titles) or 'term-resolved-description' for descriptions
  referenceWrapper?: boolean;
  // when priorityLanguage exists should resolve the label according to this property. Fallback is the jurisdiction
  priorityLanguage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LabelsResolverService {
  constructor(private store: Store) {}

  public showTermId = false;

  /**
   * @param value can be either a labelId or a single reference for a labelIf (i.e. [[labelId:description]])
   * @param context the label context
   * @returns resolvedLabel the resolved label
   */
  // FIXME: APINEW-2082 this method should not resolve references but rather just resolve a label id.
  //  Cases where reference resolution is required should change to use resolveReferences
  public resolveValue(value: string, context?: LabelResolverContext) {
    let adaptedValue = this.adaptReferenceValue(value);
    const field = this.calculateField(adaptedValue, context);
    adaptedValue = adaptedValue?.split(':')[0];
    const jurisdiction = context?.jurisdiction;
    const priorityLanguage = context?.priorityLanguage;
    let result: string;

    //1 - try to get labels according to priority country
    result = this.getRulesetlabels(jurisdiction, context?.ruleSet, adaptedValue, field, priorityLanguage);

    //2 - if no result try to get labels according to static labels
    if (!result) {
      const labels = this.store.selectSnapshot<LabelsObject>(SharedSelectors.staticLabels);
      let label = SharedState.retrieveLabel(labels, value);
      label = this.retrieveLanguageTranslatedLabel(priorityLanguage, label);
      result = label ? label[field] : (context?.defaultValue ?? value);
    }

    forOwn(
      context?.parameters ?? { jurisdiction: isArray(jurisdiction) ? jurisdiction[0] : jurisdiction },
      (val, key) => (result = result?.replaceAll(`\$\{${key}\}`, val))
    );

    const replaceFunction = context?.referenceWrapper ? termsResolverModalReplaceFunction() : undefined;
    return context?.discardReplaceReferences ? result : this.replaceReferences(result, replaceFunction, context);
  }

  public resolveReferences(value: string, context: LabelResolverContext) {
    return this.replaceReferences(value, undefined, context);
  }

  private adaptReferenceValue(value: string) {
    return value
      ?.replace(/:definition/g, ':description')
      .replace(/:term/g, ':title')
      .replace(/#\w\w\w/, '');
  }

  private calculateField(value: string, context: LabelResolverContext) {
    if (this.showTermId) {
      return 'id';
    } else if (value?.indexOf(':') !== -1) {
      return value?.split(':')[1] as 'description' | 'title';
    } else {
      return context?.field || 'title';
    }
  }

  private getRulesetlabels(
    jurisdiction: string | string[],
    ruleSet: string,
    adaptedValue: string,
    field: string,
    priorityLanguage: string
  ): string {
    let result: string;
    if (ruleSet && jurisdiction) {
      let label: Label;
      if (isArray(jurisdiction)) {
        jurisdiction.forEach(value => {
          const rulesLabels = SharedState.retrieveRuleLabels(
            this.store.selectSnapshot(SharedSelectors.rulesLabels),
            value,
            ruleSet
          );
          if (rulesLabels) {
            const tmpLabel = SharedState.retrieveLabel(rulesLabels, adaptedValue);
            if (!label || label.updatedAt < tmpLabel.updatedAt) {
              label = tmpLabel;
            }
          }
        });
      } else {
        const rulesLabels = SharedState.retrieveRuleLabels(
          this.store.selectSnapshot(SharedSelectors.rulesLabels),
          jurisdiction,
          ruleSet
        );
        if (rulesLabels) {
          label = SharedState.retrieveLabel(rulesLabels, adaptedValue);
        }
      }
      label = this.retrieveLanguageTranslatedLabel(priorityLanguage, label);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result = (label as any)?.[field];
    }
    return result;
  }

  private retrieveLanguageTranslatedLabel(priorityLanguage: string, label: Label) {
    if (priorityLanguage) {
      const translatedLabel = label?.translations?.[priorityLanguage?.toLowerCase()];
      if (translatedLabel) {
        label = {
          id: label.id,
          title: translatedLabel?.title,
          description: translatedLabel?.description
        };
      }
    }
    return label;
  }

  public resolveLabelId(labelId: string, context: LabelResolverContext): Observable<Label> {
    const definition = this.resolveValue(labelId, {
      jurisdiction: context.jurisdiction,
      ruleSet: context.ruleSet,
      field: 'description',
      discardReplaceReferences: true,
      contentProviderId: context.contentProviderId,
      priorityLanguage: context.priorityLanguage,
      parameters: context.parameters
    });
    const defaultJurisdiction: string = isArray(context.jurisdiction) ? context.jurisdiction[0] : context.jurisdiction;
    const unresolvedReferences = this.getUnresolvedReferences(definition);
    const labelsIdsNotAvailableOnStore = this.getLabelsNotAvailableOnStore(
      unresolvedReferences,
      defaultJurisdiction,
      context.ruleSet
    );

    if (labelsIdsNotAvailableOnStore?.length > 0) {
      return this.store
        .dispatch(
          new AddRuleSetLabels({
            jurisdiction: defaultJurisdiction,
            contentProviderId: context.contentProviderId || '',
            ruleSetType: context.ruleSet,
            labelsIds: unresolvedReferences
          })
        )
        .pipe(
          map(() =>
            this.updateContent(labelId, definition, {
              ruleSet: context.ruleSet,
              jurisdiction: context.jurisdiction,
              priorityLanguage: context.priorityLanguage,
              parameters: context.parameters
            })
          )
        );
    } else {
      return of(
        this.updateContent(labelId, definition, {
          ruleSet: context.ruleSet,
          jurisdiction: context.jurisdiction,
          priorityLanguage: context.priorityLanguage,
          parameters: context.parameters
        })
      );
    }
  }

  public replaceReferences(
    content: string,
    replaceFunction?: (content: string, ref: string, resolvedValue: string) => string,
    context?: LabelResolverContext
  ): string {
    content = content?.replace(/:definition/g, ':description');
    for (let taxonomyEntry: RegExpExecArray; (taxonomyEntry = this.regExp.exec(content)); ) {
      const ref = taxonomyEntry[0];
      const separatorIndex = ref.indexOf(':');
      const termId = ref.substring(2, separatorIndex === -1 ? ref.length - 2 : separatorIndex);
      const newContext = context ? cloneDeep(context) : {};
      newContext.field = ref.indexOf(':description') > -1 ? 'description' : 'title';

      const resolvedValue = this.resolveValue(termId, newContext);
      content = replaceFunction ? replaceFunction(content, ref, resolvedValue) : content.replace(ref, resolvedValue);
    }

    return content;
  }

  private updateContent(id: string, definition: string, context: LabelResolverContext): Label {
    const description = this.replaceReferences(definition, termsResolverModalReplaceFunction(), context);
    const title = this.resolveValue(id, context);

    return { id, title, description };
  }

  private getLabelsNotAvailableOnStore(labelsIds: string[], jurisdiction: string, ruleSet: string): string[] {
    const key = `${jurisdiction}:${ruleSet}`;
    const existingLabels = this.store.selectSnapshot<{ [key: string]: LabelsObject }>(SharedSelectors.rulesLabels)[key];

    return labelsIds.filter(
      (labelId, i, self) => (!existingLabels || !existingLabels[labelId]) && self.indexOf(labelId) === i
    );
  }

  public getUnresolvedReferences(content: string): string[] {
    return (content?.match(this.regExp) || []).map(ref => {
      const separatorIndex = ref.indexOf(':');
      return ref.substring(2, separatorIndex === -1 ? ref.length - 2 : separatorIndex);
    });
  }

  private get regExp(): RegExp {
    return new RegExp(`\\[\\[.*?]]`, 'g');
  }
}

const termsResolverModalReplaceFunction = () => {
  return (content: string, ref: string, resolvedValue: string) => {
    const termResolvedClass = ref.indexOf(':description') > 0 ? 'term-resolved-description' : 'term-resolved';
    const termId = ref.replace(/\[/g, '').replace(/]/g, '').split(':')[0];
    return content.replace(ref, `<span class="${termResolvedClass}" data-id="${termId}">${resolvedValue}</span>`);
  };
};
