import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { LabelResolverContext, LabelsResolverService } from '../../../services/labelsResolver.service';
import { Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-terms-resolver-modal',
  templateUrl: './labels-resolver-modal.component.html',
  styleUrls: ['./labels-resolver-modal.component.scss', './labels-resolver-modal.phone.component.scss']
})
export class LabelsResolverModalComponent implements OnInit {
  constructor(
    private labelsResolverService: LabelsResolverService,
    private store: Store
  ) {}

  @Output() lastBackClicked: EventEmitter<void> = new EventEmitter();
  @Input() content: string;
  @Input() title: string;
  @Input() ruleSet: string;
  @Input() jurisdiction: string;
  @Input() contentProviderId: string;
  @Input() showLastBack = false;
  @Input() closeEmitsLastBack = false;
  @Input() priorityLanguage: string;
  @Input() contextParameters: { [key: string]: string };

  public refreshListeners$ = new Subject<void>();
  public currentContent: string;
  public currentTitle: string;
  public conceptTracker: { title: string; content: string }[] = [];

  ngOnInit(): void {
    this.currentContent = this.content;
    this.currentTitle = this.title;
    this.conceptTracker.push({ title: this.currentTitle, content: this.currentContent });
  }

  public goToPreviousConcept() {
    this.conceptTracker.pop();
    if (this.conceptTracker.length > 0) {
      this.currentContent = this.conceptTracker[this.conceptTracker.length - 1].content;
      this.currentTitle = this.conceptTracker[this.conceptTracker.length - 1].title;
      setTimeout(() => {
        this.refreshListeners$.next();
      }, 10);
    }
    if (this.lastBackClicked && this.conceptTracker.length === 0) {
      this.lastBackClicked.emit();
    }
  }

  public onResolvedLabelClick() {
    return (termId: string) => {
      const context: LabelResolverContext = {
        jurisdiction: this.jurisdiction,
        ruleSet: this.ruleSet,
        contentProviderId: this.contentProviderId,
        priorityLanguage: this.priorityLanguage,
        parameters: this.contextParameters
      };
      this.labelsResolverService
        .resolveLabelId(termId, context)
        .pipe(first())
        .subscribe(result => {
          this.currentTitle = result.title;
          this.currentContent = result.description;
          this.conceptTracker.push({ title: this.currentTitle, content: this.currentContent });
          setTimeout(() => {
            this.refreshListeners$.next();
          }, 10);
        });
    };
  }

  getContext(): LabelResolverContext {
    return {
      referenceWrapper: true,
      jurisdiction: this.jurisdiction,
      ruleSet: this.ruleSet,
      contentProviderId: this.contentProviderId,
      priorityLanguage: this.priorityLanguage,
      parameters: this.contextParameters
    };
  }
}
