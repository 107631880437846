<div class="wrapper">
  <div class="apps-setting-header">
    <span class="back-button" (click)="this.goBack.emit($event)">
      <i inlineSVG="assets/chevron_right.svg"></i>
    </span>
    <span class="title">{{ name }}</span>
  </div>

  <img
    *ngIf="keycloakToken?.photoId; else elseBlock"
    [src]="photoUrl | authImage | async"
    alt="photo"
    data-hj-suppress
  />
  <ng-template #elseBlock>
    <div class="no-image-icon">{{ keycloakToken?.firstName?.substr(0, 1) }}</div>
  </ng-template>

  <div class="input">
    <label class="input-label"> {{ 'name' | toLabel }} </label>
    <input [(ngModel)]="name" readonly="true" type="text" class="input-data" data-hj-suppress />
  </div>

  <div class="input">
    <label class="input-label">{{ 'company' | toLabel }}</label>
    <input [(ngModel)]="companyName" readonly="true" class="input-data" type="text" data-hj-suppress />
  </div>

  <div class="input">
    <label class="input-label">{{ 'email' | toLabel }}</label>
    <input [(ngModel)]="email" readonly="true" class="input-data" type="email" data-hj-suppress />
  </div>

  <button class="logout-button" (click)="handleLogout($event)" id="PRF-Logout">{{ 'logoutLabel' | toLabel }}</button>
</div>
