import { Selector } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import {
  MarketingMaterialAttribute,
  MarketingMaterialInstrument,
  MarketingMaterialResults,
  Option,
  RuleSetsInfo,
  SupportedFootnotesAndDisclaimerLanguages
} from '../../../generated/apps-api';
import { MarketingMaterialExtendedQuestion } from '../models/marketingMaterialExtendedQuestion';
import {
  JurisdictionLabelsWithVariables,
  LegalEntityMode,
  MarketingMaterialStateModel,
  MMLegalEntityWithCountry,
  SelectedDisclaimerLanguage,
  SelectedLegalEntity
} from './marketing-material.model';
import { MarketingMaterialState } from './marketing-material.state';

// required (https://github.com/ngxs/store/issues/541) otherwise when the state changes, all selectors
// will fire. The distinctUntilChanged won't work since we do a cloneDeep to fore the immutability
export class MarketingMaterialSelectors {
  @Selector([MarketingMaterialState.distributionCountries])
  public static distributionCountries(
    distributionCountries: MarketingMaterialStateModel['distributionCountries']
  ): string[] {
    return cloneDeep(distributionCountries);
  }

  @Selector([MarketingMaterialState.allCountries])
  public static allCountries(allCountries: MarketingMaterialStateModel['allCountries']): string[] {
    return cloneDeep(allCountries);
  }

  @Selector([MarketingMaterialState.selectedDistributionCountries])
  public static selectedDistributionCountries(
    selectedDistributionCountries: MarketingMaterialStateModel['selectedDistributionCountries']
  ): string[] {
    return cloneDeep(selectedDistributionCountries);
  }

  @Selector([MarketingMaterialState.selectedDistributionCountriesWithResults])
  public static selectedDistributionCountriesWithResults(
    selectedDistributionCountriesWithResults: MarketingMaterialStateModel['selectedDistributionCountriesWithResults']
  ): string[] {
    return cloneDeep(selectedDistributionCountriesWithResults);
  }

  @Selector([MarketingMaterialState.selectedLegalEntitiesIdsWithResults])
  public static selectedLegalEntitiesIdsWithResults(
    selectedLegalEntitiesIdsWithResults: MarketingMaterialStateModel['selectedLegalEntitiesIdsWithResults']
  ): SelectedLegalEntity[] {
    return cloneDeep(selectedLegalEntitiesIdsWithResults);
  }

  @Selector([MarketingMaterialState.selectedLegalEntitiesMode])
  public static selectedLegalEntitiesMode(
    selectedLegalEntitiesMode: MarketingMaterialStateModel['selectedLegalEntitiesMode']
  ): LegalEntityMode {
    return cloneDeep(selectedLegalEntitiesMode);
  }

  @Selector([
    MarketingMaterialState.selectedLegalEntitiesIdsWithResults,
    MarketingMaterialState.legalEntities,
    MarketingMaterialState.selectedLegalEntitiesMode
  ])
  public static selectedLegalEntities(
    selectedLegalEntitiesIdsWithResults: MarketingMaterialStateModel['selectedLegalEntitiesIdsWithResults'],
    legalEntities: MarketingMaterialStateModel['legalEntities'],
    selectedLegalEntitiesMode: MarketingMaterialStateModel['selectedLegalEntitiesMode']
  ): MMLegalEntityWithCountry[] {
    return cloneDeep(
      selectedLegalEntitiesIdsWithResults
        ?.map(selectedLegalEntity => {
          const legalEntityWithCountry: MMLegalEntityWithCountry = cloneDeep(
            legalEntities.find(le => le.id === selectedLegalEntity.legalEntityId)
          );
          if (selectedLegalEntitiesMode === LegalEntityMode.PER_COUNTRY) {
            legalEntityWithCountry.partialLicenses = legalEntityWithCountry.partialLicenses.filter(
              _license => _license.countryCode === selectedLegalEntity.licenseCountry
            );
          }
          legalEntityWithCountry.country = selectedLegalEntity.licenseCountry;
          return cloneDeep(legalEntityWithCountry);
        })
        .sort((le1, le2) => le1.name.localeCompare(le2.name))
    );
  }

  @Selector([MarketingMaterialState.labelsWithVariables])
  public static labelsWithVariables(
    labelsWithVariables: MarketingMaterialStateModel['labelsWithVariables']
  ): JurisdictionLabelsWithVariables[] {
    return cloneDeep(labelsWithVariables);
  }

  @Selector([MarketingMaterialState.ruleSetsInfo])
  public static ruleSetsInfo(ruleSetsInfo: MarketingMaterialStateModel['ruleSetsInfo']): RuleSetsInfo[] {
    return cloneDeep(ruleSetsInfo);
  }

  @Selector([MarketingMaterialState.attributes])
  public static attributes(attributes: MarketingMaterialStateModel['attributes']): MarketingMaterialAttribute[] {
    return cloneDeep(attributes);
  }

  @Selector([MarketingMaterialState.questions])
  public static questions(questions: MarketingMaterialStateModel['questions']): MarketingMaterialExtendedQuestion[] {
    return cloneDeep(questions);
  }

  @Selector([MarketingMaterialState.filteredResults])
  public static filteredResults(results: MarketingMaterialStateModel['results']): MarketingMaterialResults {
    return cloneDeep(results);
  }

  @Selector([MarketingMaterialState.results])
  public static results(results: MarketingMaterialStateModel['results']): MarketingMaterialResults {
    return cloneDeep(results);
  }

  @Selector([MarketingMaterialState.supportedDisclaimerLanguages])
  public static supportedDisclaimerLanguages(
    supportedDisclaimerLanguages: SupportedFootnotesAndDisclaimerLanguages[]
  ): SupportedFootnotesAndDisclaimerLanguages[] {
    return cloneDeep(supportedDisclaimerLanguages);
  }

  @Selector([MarketingMaterialState.selectedDisclaimerLanguages])
  public static selectedDisclaimerLanguages(
    selectedDisclaimerLanguages: SelectedDisclaimerLanguage[]
  ): SelectedDisclaimerLanguage[] {
    return cloneDeep(selectedDisclaimerLanguages);
  }

  @Selector([MarketingMaterialState.selectInstrument])
  public static instruments(instruments: MarketingMaterialInstrument): MarketingMaterialInstrument {
    return cloneDeep(instruments);
  }

  @Selector([MarketingMaterialState.isSearchInstrumentEnable])
  public static isSearchInstrumentEnable(isSearchInstrumentEnable: boolean): boolean {
    return cloneDeep(isSearchInstrumentEnable);
  }

  @Selector([MarketingMaterialState.applicableCustomActionTagsOptions])
  public static applicableCustomActionTagsOptions(applicableCustomActionTagsOptions: Option[]): Option[] {
    return cloneDeep(applicableCustomActionTagsOptions);
  }

  @Selector([MarketingMaterialState.applicablePerformanceDataOptions])
  public static applicablePerformanceDataOptions(applicablePerformanceDataOptions: Option[]): Option[] {
    return cloneDeep(applicablePerformanceDataOptions);
  }

  @Selector([MarketingMaterialState.customActionsTagValues])
  public static customActionsTagValues(customActionsTagValues: string[]): string[] {
    return cloneDeep(customActionsTagValues);
  }

  @Selector([MarketingMaterialState.performanceDataValues])
  public static performanceDataValues(performanceDataValues: string[]): string[] {
    return cloneDeep(performanceDataValues);
  }

  @Selector([MarketingMaterialState.generatedOn])
  public static generatedOn(generatedOn: string): string {
    return cloneDeep(generatedOn);
  }

  @Selector([MarketingMaterialState.generatedByFullName])
  public static generatedByFullName(generatedByFullName: string): string {
    return cloneDeep(generatedByFullName);
  }
}
