<div title="open menu" (click)="toggle()" class="icon phone" inlineSVG="assets/hamburger.svg"></div>
<div title="open menu" (click)="toggle()" class="icon tablet" inlineSVG="assets/hamburger_32.svg"></div>

<div class="menu-background" (click)="toggle()" [ngClass]="{ opened: opened }"></div>

<div *ngIf="!showProfileTab" [ngClass]="{ 'opened-menu': true, opened: opened }">
  <div class="menu-header-container">
    <div class="title">Menu</div>
    <i
      class="close"
      aria-label="Close"
      labelId="closeButton"
      [inlineSVG]="'assets/modal-cross.svg'"
      (click)="toggle()"
    ></i>
  </div>

  <div class="welcome-msg phone">
    <img
      *ngIf="keycloakToken?.photoId; else elseBlock"
      [src]="photoUrl | authImage | async"
      alt="photo"
      data-hj-suppress
    />
    <ng-template #elseBlock>
      <div class="no-image-icon">{{ keycloakToken?.firstName?.substr(0, 1) }}</div>
    </ng-template>

    <span>
      <span class="welcome-first-name" data-hj-suppress>{{ keycloakToken?.firstName }}</span>
      <span class="welcome-organization-name" data-hj-suppress>
        <span *ngIf="(userPreferences$ | async)?.preferredLegalEntityId">{{
          preferredLegalEntity((userPreferences$ | async)?.preferredLegalEntityId)?.name
        }}</span>
        <span *ngIf="!(userPreferences$ | async)?.preferredLegalEntityId" data-hj-suppress
          >{{ userCompanyName$ | async }} &#9679;&nbsp;&nbsp;No Legal Entity Defined</span
        >
      </span>
    </span>
  </div>

  <div *ngIf="!isDashboard" class="menu-header-option back-to-apps phone" (click)="backToApps()">
    <div class="title">{{ 'phoneMenuBackToAppsLabel' | toLabel }}</div>
    <i [inlineSVG]="'assets/accordion-icon.svg'"></i>
  </div>
  <div class="menu-header-option phone" (click)="goToProfile()">
    <div class="title">{{ 'profile' | toLabel }}</div>
    <i [inlineSVG]="'assets/accordion-icon.svg'"></i>
  </div>
  <div class="menu-header-option phone" (click)="goToSettings()">
    <div class="title">{{ 'settings' | toLabel }}</div>
    <i [inlineSVG]="'assets/accordion-icon.svg'"></i>
  </div>
  <div class="menu-header-option" (click)="contactSupport()">
    <div class="title">{{ 'phoneMenuSupportLabel' | toLabel }}</div>
    <i [inlineSVG]="'assets/accordion-icon.svg'"></i>
  </div>
  <div class="menu-header-option" (click)="privacyPolicy()">
    <div class="title">{{ 'phoneMenuPrivacyPolicyLabel' | toLabel }}</div>
    <i [inlineSVG]="'assets/accordion-icon.svg'"></i>
  </div>
  <div class="menu-header-option phone" (click)="logout()">
    <div class="title">{{ 'logoutLabel' | toLabel }}</div>
  </div>

  <div class="disclaimer">{{ currentYear }} © Apiax AG. All rights reserved</div>
</div>

<app-hamburger-settings *ngIf="showSettingsTab" (goBack)="goToMainMenu($event)"></app-hamburger-settings>
<app-hamburger-profile *ngIf="showProfileTab" (goBack)="goToMainMenu($event)"></app-hamburger-profile>
