import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ApiaxKeycloakToken } from 'src/app/portal/model/apiaxKeycloakToken.model';
import { UserService } from 'src/app/portal/services/user.service';
import { LegalEntity, UserPreferences } from 'src/generated/apps-api';
import { PortalSelectors } from '../../../portal/state/portal.selectors';
import { SharedSelectors } from '../../state/shared.selectors';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: [
    './hamburger-menu.component.scss',
    './hamburger-menu.phone.component.scss',
    './hamburger-menu.tablet.component.scss'
  ]
})
export class HamburgerComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private userService: UserService,
    private store: Store
  ) {}

  public opened = false;
  public showProfileTab = false;
  public showSettingsTab = false;

  @Input() isDashboard = false;

  public userPreferences$: Observable<UserPreferences>;
  public legalEntities$: Observable<LegalEntity[]>;
  public userCompanyName$: Observable<string>;

  public legalEntities: LegalEntity[];

  ngOnInit(): void {
    this.userPreferences$ = this.store.select<UserPreferences>(SharedSelectors.userPreferences);
    this.legalEntities$ = this.store.select<LegalEntity[]>(PortalSelectors.legalEntities);
    this.userCompanyName$ = this.store.select<string>(PortalSelectors.userCompanyName);

    this.legalEntities$.subscribe(les => (this.legalEntities = les));
  }

  ngOnDestroy(): void {
    document.body.style.overflow = 'initial';
    document.documentElement.style.overflow = 'initial';
  }

  toggle() {
    this.opened = !this.opened;
    if (!this.opened) {
      this.showProfileTab = false;
      this.showSettingsTab = false;
    }
    document.body.style.overflow = this.opened ? 'hidden' : 'initial';
    document.documentElement.style.overflow = this.opened ? 'hidden' : 'initial';
  }

  backToApps() {
    this.router.navigateByUrl('/');
  }

  contactSupport() {
    window.open('https://docs.apiax.io/hc/en-gb/requests/new', '_blank');
  }

  privacyPolicy() {
    window.open('https://www.iubenda.com/privacy-policy/91497267', '_blank');
  }

  goToSettings() {
    this.showSettingsTab = true;
  }

  goToProfile() {
    this.showProfileTab = true;
  }

  goToMainMenu(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.showProfileTab = false;
    this.showSettingsTab = false;
    this.opened = true;
  }

  public logout() {
    return this.userService.logout();
  }

  public get currentYear() {
    return new Date().getFullYear();
  }

  public get keycloakToken(): ApiaxKeycloakToken {
    return this.userService.keycloakToken;
  }

  public preferredLegalEntity(id: string): LegalEntity {
    return this.legalEntities?.find(value => value.id === id);
  }

  public get photoUrl(): string {
    return this.userService.photoUrl;
  }
}
