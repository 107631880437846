import {
  CrossBorderActivitiesAndDocuments,
  CrossBorderEvaluationInformation,
  CrossBorderProduct,
  PortfolioEntry
} from 'src/generated/apps-api';
import { CrossBorderAction } from 'src/generated/apps-api/model/crossBorderAction';
import { CrossBorderAttribute } from 'src/generated/apps-api/model/crossBorderAttribute';
import { CrossBorderQuestion, ExtendedProduct } from './cross-border.model';

export enum CrossborderInstrumentsAction {
  ADD_INSTRUMENTS_QUESTION = '(Cross-border) [Instruments] Add question',
  SET_INSTRUMENTS_QUESTIONS = '(Cross-border) [Instruments] set questions',
  SET_INSTRUMENTS_ATTRIBUTE = '(Cross-border) [Instruments] Set attribute',
  SET_INSTRUMENTS_ATTRIBUTES = '(Cross-border) [Instruments] Set attributes',
  SET_INSTRUMENTS_RESULTS = '(Cross-border) [Instruments] Set results',
  SET_INSTRUMENTS_STEP_NUMBER = '(Cross-border) [Instruments] Set step number',
  BACK_TO_RESULTS = '(Cross-border) [Instruments] Back to Results',
  SHOW_OVERLAY = '(Cross-border) [Instruments] Show Overlay',
  USE_PORTFOLIO = '(Cross-border) [Instruments] Set Use Portfolio on evaluation',
  SET_PORTFOLIO = '(Cross-border) [Instruments] Set Portfolio',
  SET_INSTRUMENTS_PRODUCTS = '(Cross-border) [Instruments] Set Instruments Products',
  REMOVE_INSTRUMENTS_RESULTS = '(Cross-border) [Instruments] Remove Instruments Results',
  UPDATE_INSTRUMENTS_CTAS = '(Cross-border) [Instruments] Update Instruments CTAs',
  INSTRUMENTS_CHANGED = '(Cross-border) [Audit BAM data] Instruments added or changed'
}

export class AddInstrumentsQuestion {
  public static readonly type = CrossborderInstrumentsAction.ADD_INSTRUMENTS_QUESTION;

  constructor(
    public payload?: {
      question: CrossBorderQuestion;
      evaluationInformation?: CrossBorderEvaluationInformation;
    }
  ) {}
}

export class SetInstrumentsQuestions {
  public static readonly type = CrossborderInstrumentsAction.SET_INSTRUMENTS_QUESTIONS;

  constructor(
    public payload?: {
      questions: CrossBorderQuestion[];
    }
  ) {}
}

export class SetInstrumentsAttribute {
  public static readonly type = CrossborderInstrumentsAction.SET_INSTRUMENTS_ATTRIBUTE;

  constructor(public payload?: { newAttribute: CrossBorderAttribute }) {}
}

export class SetInstrumentsAttributes {
  public static readonly type = CrossborderInstrumentsAction.SET_INSTRUMENTS_ATTRIBUTES;

  constructor(public payload?: { attributes: CrossBorderAttribute[] }) {}
}

export interface SetInstrumentsResultsPayload {
  activities: { [key: string]: CrossBorderAction[] };
  evaluationInformation?: CrossBorderEvaluationInformation;
  questions?: CrossBorderQuestion[];
  attributes?: CrossBorderAttribute[];
  portfolioEntries?: PortfolioEntry[];
  usePortfolio?: boolean;
  showOverlay?: boolean;
  instrumentsProducts?: ExtendedProduct[];
}
export class SetInstrumentsResults {
  public static readonly type = CrossborderInstrumentsAction.SET_INSTRUMENTS_RESULTS;

  constructor(public payload?: SetInstrumentsResultsPayload) {}
}

export class SetInstrumentsStepNumber {
  public static readonly type = CrossborderInstrumentsAction.SET_INSTRUMENTS_STEP_NUMBER;

  constructor(public payload?: { stepNumber: number }) {}
}

export class BackToResults {
  public static readonly type = CrossborderInstrumentsAction.BACK_TO_RESULTS;

  constructor() {}
}

export class ShowOverlay {
  public static readonly type = CrossborderInstrumentsAction.SHOW_OVERLAY;

  constructor(public payload?: { showOverlay: boolean }) {}
}

export class UsePortfolio {
  public static readonly type = CrossborderInstrumentsAction.USE_PORTFOLIO;

  constructor(public payload?: { usePortfolio: boolean }) {}
}

export class SetPortfolio {
  public static readonly type = CrossborderInstrumentsAction.SET_PORTFOLIO;

  constructor(public payload?: { portfolioEntries: PortfolioEntry[] }) {}
}

export class SetInstrumentsProducts {
  public static readonly type = CrossborderInstrumentsAction.SET_INSTRUMENTS_PRODUCTS;

  constructor(public payload?: { products: CrossBorderProduct[] }) {}
}

export class RemoveInstrumentsResults {
  public static readonly type = CrossborderInstrumentsAction.REMOVE_INSTRUMENTS_RESULTS;

  constructor() {}
}

export class UpdateInstrumentsCtas {
  public static readonly type = CrossborderInstrumentsAction.UPDATE_INSTRUMENTS_CTAS;

  constructor(
    public payload?: {
      results: { [key: string]: CrossBorderActivitiesAndDocuments };
      scenarioIndex: number;
    }
  ) {}
}

// audit bam data

export class InstrumentsChangedOrAdded {
  public static readonly type = CrossborderInstrumentsAction.INSTRUMENTS_CHANGED;

  constructor() {}
}
